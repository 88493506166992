import React from "react";
import AllView from "../views/all-view"
import { graphql } from 'gatsby'
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { useUIState } from "../contexts/UIStateContext";
import LogoAnimation from "../components/LogoAnimation/LogoAnimation";
import Seo from "../components/Seo/Seo"

/* const View = styled(Container)`
  min-height: 100vh;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffff0;
  overflow: hidden;

  @media (max-width: 768px) {
    min-height: 100vh;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  h4 {
    font-size: 1.5rem;
  }
`;

const LogoWrapper = styled(Col)`
  svg {
    width: 350px;
    max-width: 80vw;
  }

  @media (min-width: 762px) {
    svg{
        width: 400px;
    }
   }

  @media (min-width: 992px) {
    svg {
        width: 450px;
    }
  }

  @media (min-width: 1200px) {
    svg {
        width: 550px;
    }       
   }
`;

const Index = ({ location }) => {
  const { mobileoffset, totalOffset } = useUIState();

  return (
    <View
      fluid
      className="overflow-hidden px-2 px-lg-4"
      offset={totalOffset}
      mobileoffset={mobileoffset}
    >
      <Seo
        title="Architektur + Design in Aschaffenburg"
        description="Resonator Coop Architektur + Design in Aschaffenburg ist eine progressive Kooperation von Architekten und Designern für anspruchsvolle Aufgaben in den Bereichen Bauen, Planen, Gestaltung und Design."
        type="WebPage" />
      <section id="intro">
        <Row>
          <LogoWrapper>
            <LogoAnimation />
          </LogoWrapper>
        </Row>
      </section>
    </View>
  );
};

export default Index;
 */


const Index = (props) => {
  const projects = props.data.architektur.nodes
  const imgData = props.data.images.nodes
  const location = props.location
  const category = props.data.category
  const categories = props.data.categories && props.data.categories.nodes

  return <AllView projects={projects} category={category} categories={categories} location={location} images={imgData} />
};

export default Index;


export const query = graphql`
  query($category: String) {
    architektur: allArchitektur(sort: {fields: order}){
      nodes {
        title
        slug
        category
        images {
          alt
          alt_over
        }
      }
    }
    images: allFile(filter: {sourceInstanceName: {eq: $category}, 
      extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(avif)/"},
      name: {regex: "/^([0-9 a-z A-Z]*)(_*)([^0-9]*)$/" }}) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            outputPixelDensities: [0.25, 0.5, 0.75, 1, 2]
            width: 300
          )
        }
        relativePath
      }
    }
    category: categories(_id: {eq: 0}) {
      _id
      slug
      name
      description
      type
      meta
      body
      files { 
        url
        text1
        text2
      }
    }
    categories: allCategories {
      nodes {
        _id
        slug
        name
        description
        type
        meta
        body
        files {
          url
          text1
          text2
        }
      }
    }
  }
`